import { i18n } from '../../symphony';
import { CtaName, SectionType } from './global-interfaces';

export default () => ({
	// calling function for ssr
	experimentDate: '0001-01-01T00:00:00',
	experimentId: 18,
	experimentName: 'web_homepage_courses',
	flowId: 75,
	flowName: 'homepage_courses_default',
	language: null,
	version: 1,
	platform: '0',
	pages: [
		{
			pageId: 0,
			pageName: 'header',
			version: 'v1',
			data: {
				title: 'web-home.header-title',
				description: 'web-home.header-description',
				highlightedText: 'web-home.header-highlighted-text',
				actionText: i18n.t('web-home.header-action-text'),
				ctaName: CtaName.SEE_MORE_OPTIONS,
				sectionType: SectionType.MAIN_HEADER_PAGE,
			},
		},
		{
			pageId: 2,
			pageName: 'why_sololearn',
			version: 'v1',
			data: {
				title: '',
				benefits: [
					{
						title: i18n.t('web-home.benefit1-title'),
						description: i18n.t('web-home.benefit1-description'),
						highlightedText: i18n.t(
							'web-home.benefit1-highlighted-text',
						),
						cta: i18n.t(
							'web-home.benefit1-cta',
						),
						icon: 'https://assets.sololearn.com/home-why-solo-1.svg',
						size: 'medium',
						ctaUrl: '/users/signup',
						ctaName: CtaName.START_FROM_SCRATCH,
						sectionType: SectionType.USP,
					},
					{
						title: i18n.t('web-home.benefit2-title'),
						description: i18n.t('web-home.benefit2-description'),
						highlightedText: i18n.t(
							'web-home.benefit2-highlighted-text',
						),
						cta: i18n.t(
							'web-home.benefit2-cta',
						),
						icon: 'https://assets.sololearn.com/home-why-solo-2.svg',
						size: 'medium',
						ctaUrl: '/users/signup',
						ctaName: CtaName.DO_QUICK_LESSON,
						sectionType: SectionType.USP,
					},
					{
						title: i18n.t('web-home.benefit3-title'),
						description: i18n.t('web-home.benefit3-description'),
						highlightedText: i18n.t(
							'web-home.benefit3-highlighted-text',
						),
						cta: i18n.t(
							'web-home.benefit3-cta',
						),
						icon: 'https://assets.sololearn.com/home-why-solo-3.svg',
						size: 'medium',
						ctaUrl: '/users/signup',
						ctaName: CtaName.BECOME_CERTIFIED,
						sectionType: SectionType.USP,
					},
					{
						title: i18n.t('web-home.benefit4-title'),
						description: i18n.t('web-home.benefit4-description'),
						highlightedText: i18n.t(
							'web-home.benefit4-highlighted-text',
						),
						icon: 'https://blob.sololearn.com/assets/home-whu-solo-4-rebranding.svg',
						size: 'large',
						sectionType: SectionType.MOBILE_APPS_COURSES,
					},
					{
						title: i18n.t('web-home.benefit5-title'),
						description: i18n.t('web-home.benefit5-description'),
						highlightedText: i18n.t(
							'web-home.benefit5-highlighted-text',
						),
						icon: 'https://assets.sololearn.com/home-why-solo-5.svg',
						size: 'medium',
						cta: i18n.t(
							'web-home.benefit5-cta',
						),
						ctaType: 'success',
						ctaUrl: '/learn',
						ctaName: CtaName.EXPLORE_FULL_CATALOG,
						sectionType: SectionType.MOBILE_APPS_COURSES,
					},
				],
			},
		},
		{
			pageId: 3,
			pageName: 'perfect_platform',
			version: 'v1',
			data: {
				title: i18n.t('web-home.perfect-platform-title'),
				platform: [
					{
						name: i18n.t('web-home.perfect-platform-students-name'),
						description: i18n.t(
							'web-home.perfect-platform-students-description',
						),
						icon: 'https://blob.sololearn.com/assets/home-perfect-platform-1-rebranding.svg',
						cta: i18n.t('web-home.perfect-platform-cta1'),
						ctaName: CtaName.LEARN_FOR_FREE,
						sectionType: SectionType.STUDENTS_VS_PROFESSIONALS,
					},
					{
						name: i18n.t(
							'web-home.perfect-platform-professionals-name',
						),
						description: i18n.t(
							'web-home.perfect-platform-professionals-description',
						),
						icon: 'https://assets.sololearn.com/home-perfect-platform-2.svg',
						cta: i18n.t('web-home.perfect-platform-cta2'),
						ctaName: CtaName.BOOST_YOUR_CAREER,
						sectionType: SectionType.STUDENTS_VS_PROFESSIONALS,
					},
				],
			},
		},
		{
			pageId: 4,
			pageName: 'community',
			version: 'v1',
			data: {
				currentCommunity: 42790091,
				updatedDate: 'Tue May 11 2021 16:05:40',
				averageUsersCount1: 1,
				averageUsersCount2: 2,
				updateInterval: 5,
				title: i18n.t('web-home.community-title'),
				description: i18n.t('web-home.community-description'),
				ctaName: CtaName.JOIN_TODAY,
				sectionType: SectionType.COMMUNITY,
			},
		},
		{
			pageId: 5,
			pageName: 'dream_job',
			version: 'v1',
			data: {
				leftText: i18n.t('web-home.dream-job-left-text'),
				rightText: i18n.t('web-home.dream-job-right-text'),
				textHighlighted: i18n.t('web-home.dream-job-text-highlighted'),
				benefits: [
					i18n.t('web-home.dream-job-benefits1'),
					i18n.t('web-home.dream-job-benefits2'),
					i18n.t('web-home.dream-job-benefits3'),
					i18n.t('web-home.dream-job-benefits4'),
					i18n.t('web-home.dream-job-benefits5'),
					i18n.t('web-home.dream-job-benefits6'),
					i18n.t('web-home.dream-job-benefits7'),
				],
			},
		},
		{
			pageId: 6,
			pageName: 'why_code',
			version: 'v1',
			data: {
				title: i18n.t('web-home.why-code-title'),
				titleHighlighted: i18n.t('web-home.why-code-title-highlighted'),
				description: i18n.t('web-home.why-code-description'),
				actionText: i18n.t('web-home.why-code-action-text'),
				benefits: [
					{
						title: i18n.t('web-home.why-code-benefits1-title'),
						description: i18n.t(
							'web-home.why-code-benefits1-description',
						),
						icon: 'https://assets.sololearn.com/home-why-code-1.svg',
						background: '#FBB461',
					},
					{
						title: i18n.t('web-home.why-code-benefits2-title'),
						description: i18n.t(
							'web-home.why-code-benefits2-description',
						),
						icon: 'https://assets.sololearn.com/home-why-code-2.svg',
						background: '#F35843',
					},
					{
						title: i18n.t('web-home.why-code-benefits3-title'),
						description: i18n.t(
							'web-home.why-code-benefits3-description',
						),
						icon: 'https://assets.sololearn.com/home-why-code-3.svg',
						background: '#40BF9C',
					},
				],
				cta: i18n.t('web-home.why-code-cta'),
				ctaName: CtaName.I_WANT_TO_CODE,
				sectionType: SectionType.WHY_CODE,
			},
		},
		{
			pageId: 7,
			pageName: 'pro',
			version: 'v1',
			data: {
				title: i18n.t('web-home.pro-title'),
				proIconId: 'proGold',
				packages: [
					{
						title: i18n.t('web-home.pro-packages1-title'),
						benefit: i18n.t('web-home.pro-packages1-benefit'),
						description: i18n.t(
							'web-home.pro-packages1-description',
						),
						highlightedText: i18n.t(
							'web-home.pro-packages1-highlighted-text',
						),
					},
					{
						title: i18n.t('web-home.pro-packages2-title'),
						benefit: i18n.t('web-home.pro-packages2-benefit'),
						description: i18n.t(
							'web-home.pro-packages2-description',
						),
						highlightedText: i18n.t(
							'web-home.pro-packages2-highlighted-text',
						),
					},
					{
						title: i18n.t('web-home.pro-packages3-title'),
						benefit: i18n.t('web-home.pro-packages3-benefit'),
						description: i18n.t(
							'web-home.pro-packages3-description',
						),
						highlightedText: i18n.t(
							'web-home.pro-packages3-highlighted-text',
						),
					},
				],
				actionText: i18n.t('web-home.pro-action-text'),
				offersRow: [
					{
						iconId: 'check',
						offer: i18n.t('web-home.pro-offers1'),
					},
					{
						iconId: 'check',
						offer: i18n.t('web-home.pro-offers2'),
					},
					{
						iconId: 'check',
						offer: i18n.t('web-home.pro-offers3'),
					},
					{
						iconId: 'check',
						offer: i18n.t('web-home.pro-offers4'),
					},
					{
						iconId: 'check',
						offer: i18n.t('web-home.pro-offers5'),
					},
					{
						iconId: 'check',
						offer: i18n.t('web-home.pro-offers6'),
					},
					{
						iconId: 'check',
						offer: i18n.t('web-home.pro-offers7'),
					},
				],
			},
		},
		{
			pageId: 8,
			pageName: 'user_story',
			version: 'v1',
			data: {
				stories: [
					{
						title: i18n.t('web-home.user-story1-title'),
						titleHighlighted: i18n.t(
							'web-home.user-story1-title-highlighted',
						),
						description: i18n.t('web-home.user-story1-description'),
						imageUrl:
                            'https://assets.sololearn.com/success-story-1.jpg',
						completedCourse: {
							title: i18n.t(
								'web-home.user-story1-completed-course-title',
							),
							imageUrl:
                                'https://uploads.sololearn.com/uploads/courses/1160.png',
						},
						certificate: {
							title: i18n.t(
								'web-home.user-story1-certificate-title',
							),
							icon: 'certificateV2Complete',
						},
						thanks: {
							text: i18n.t('web-home.user-story1-thanks-text'),
							iconId: 'heart',
						},
					},
					{
						title: i18n.t('web-home.user-story2-title'),
						titleHighlighted: i18n.t(
							'web-home.user-story2-title-highlighted',
						),
						description: i18n.t('web-home.user-story2-description'),
						imageUrl:
                            'https://assets.sololearn.com/success-story-2.jpg',
						completedCourse: {
							title: i18n.t(
								'web-home.user-story2-completed-course-title',
							),
							imageUrl:
                                'https://uploads.sololearn.com/uploads/courses/1160.png',
						},
						certificate: {
							title: i18n.t(
								'web-home.user-story2-certificate-title',
							),
							icon: 'certificateV2Complete',
						},
						thanks: {
							text: i18n.t('web-home.user-story2-thanks-text'),
							iconId: 'heart',
						},
					},
				],
				ctaName: CtaName.START_YOUR_SUCCESS_STORY,
				sectionType: SectionType.TESTIMONIAL,
			},
		},
		{
			pageId: 9,
			pageName: 'awards',
			version: 'v1',
			data: {
				title: i18n.t('web-home.awards-title'),
				background: '#F2F5F7',
				awards: [
					{
						iconId: 'stars',
						title: i18n.t('web-home.awards1-title'),
						text: i18n.t('web-home.awards1-text'),
						iconStyle: {
							height: '24px',
							width: '152px',
							color: '#ECAA00',
						},
					},
					{
						iconId: 'fbCircle',
						title: i18n.t('web-home.awards2-title'),
						text: i18n.t('web-home.awards2-text'),
					},
					{
						iconId: 'playMarket',
						title: i18n.t('web-home.awards3-title'),
						text: i18n.t('web-home.awards3-text'),
					},
					{
						iconId: 'edTech',
						title: i18n.t('web-home.awards4-title'),
						text: i18n.t('web-home.awards4-text'),
						iconStyle: {
							height: '62px',
							width: '62px',
						},
					},
				],
			},
		},
		{
			pageId: 10,
			pageName: 'sign_up_2',
			version: 'v1',
			data: {
				title: i18n.t('web-home.sign-up2-title'),
				actionText: i18n.t('web-home.sign-up2-action-text'),
				background: '#1F1E28',
			},
		},
	],
});

export const webHomeFirstScreenExpKey = 'web-home-first-screen';
