import React, { useEffect, useRef, useState } from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Button } from '@sololearnorg/sol';

import { Bubbles } from './Bubbles/Bubbles';
import { communityAvatars } from './communityAvatars';
import { generateRandomArray } from '../../utils/helperFunctions';
import { SectionInView } from '../../utils/SectionInView';
import {
	ActionType, CtaName, HomeSections, RequestType, SectionType,
} from '../../global-interfaces';

import s from './Community.scss';
import { IHomeCommunitySection } from '../../../../api/public/home-api';
import { Container, i18n } from '../../../../symphony';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';
import { SlHomeContext } from '../../home-context';
import { SlActions } from '../../services/ActionsService/sl-actions';

interface ICommunityProps extends IHomeCommunitySection {
	ctaName: CtaName
	sectionType: SectionType
}

export function Community({
	title,
	currentCommunity,
	description,
	updatedDate,
	averageUsersCount1,
	averageUsersCount2,
	updateInterval,
	ctaName,
	sectionType,
}: ICommunityProps): JSX.Element {
	useStyles(s);
	const { actions$ } = Container.take(SlHomeContext, SlActions);
	const element = useRef<HTMLDivElement>(null);
	SectionInView(element, 'community', HomeSections.community);

	const date = Math.round(Date.now() / 1000);

	const formatedUpdatedDate = new Date(updatedDate).getTime() / 1000;

	const [usersCount, setUsersCount] = useState<number>(
		Math.round(
			((date - formatedUpdatedDate) / updateInterval)
                * ((averageUsersCount2 + averageUsersCount1) / 2)
                + currentCommunity,
		),
	);

	const ctaClickHandler = () => {
		actions$.next({
			type: ActionType.OnCtaClick,
			requestType: RequestType.Parallel,
			data: {
				ctaName,
				sectionType,
			},
		});
	};

	useEffect(() => {
		let evenCount = false;
		const timeout = setInterval(() => {
			setUsersCount(
				(val) => val + (evenCount ? averageUsersCount1 : averageUsersCount2),
			);
			evenCount = !evenCount;
		}, updateInterval * 1000);

		return () => {
			clearInterval(timeout);
		};
	}, []);

	return (
		<section className="sl-home-community" ref={element}>
			<div className="sl-home-community__inner">
				<div className="sl-home-community__inner__texts">
					<h2 className="sl-home-community__title">{title}</h2>
					<div className="sl-home-community__indicator">
						{usersCount.toLocaleString('UE')}
					</div>
					<p className="sl-home-community__info">{description}</p>
				</div>
				<Button buttonType="success" asChild onClick={() => { ctaClickHandler(); }}>
					<LocalizedLink to="/users/signup">
						{i18n.t('web-home.community-cta')}
					</LocalizedLink>
				</Button>
				<img
					src="https://blob.sololearn.com/assets/community.svg"
					className="sl-home-community__img"
					loading="lazy"
					alt="community"
				/>
				<Bubbles avatars={generateRandomArray(communityAvatars, 8)} />
			</div>
		</section>
	);
}
